<template>
    <div class="container">
        <div class="report">
            <div class="report-title">
                <span class="report-title-text">Test Report</span>
            </div>
            <div class="report-content">
                <div class="report-content-title">
                    <span class="report-content-title-text"> {{report.title}}</span>
                </div>
                <div class="report-content-summary">
                    <span class="report-content-summary-conclusion"> {{report.conclusion}}</span>
                    <span class="report-content-summary-text-overall"> Overall:{{(report.reading+report.writing+report.listening)/4}}</span> 
                    <span class="report-content-summary-text-part"> Reading: {{report.reading}}</span>
                    <span class="report-content-summary-text-part"> Writing: {{report.writing}}</span>
                    <span class="report-content-summary-text-part"> Listening: {{report.listening}}</span>
                    <span class="report-content-summary-text-part"> Speaking: {{report.speaking}}</span>
                </div>
                <div class="report-content-chart">

                    <div class="report-content-chart-bar">
                        <div class="report-content-chart-bar-yAxios"> 
                           
                            <div class="report-content-chart-bar-yAxios-standard">
                                <span class="report-content-chart-bar-yAxios-brillent">Brillent</span>
                                <span class="report-content-chart-bar-yAxios-excelent">Excellent</span>
                                <span class="report-content-chart-bar-yAxios-pass">Pass</span>
                                <span class="report-content-chart-bar-yAxios-fail">Fail</span>
                            </div>

                            <div class="report-content-chart-bar-yAxios-score">
                                <span class="score-text" v-for="i in 16" :key="i">{{(16-i)*10}}</span>
                            </div>
                       </div> 
                    </div>

                    <div class="report-content-chart-items">
                        <div class="report-content-chart-items-reading">Reading</div>
                        <div class="report-content-chart-items-writing">Writing</div>
                        <div class="report-content-chart-items-listening">Listening</div>
                        <div class="report-content-chart-items-speaking">Speaking</div>
                    </div>
                </div>
            </div>
        </div>
    </div>   
</template>
   
  <script>
   
    export default {
        name: 'TestingReport',
        components:{
           
        },
        data(){
            return{
               report:{
                "title":"Key English Test",
                "conclusion":"Pass with Brilliant！",
                "reading": 121,
                "writing":111,
                "listening": 111,
                "speaking":111,
               }
            }
        },
       
        mounted(){
         
    },
  }
  </script>
   
   <style lang="scss" scoped>
   .container{
     display: flex;
     margin-left:auto;
     margin-right: auto;
     /*justify-content: center; /*水平居中*/
     /*align-items: center; /*垂直居中*/
     flex-direction: column;
     max-width:1200px;
   }
   .report{
       border: 1px solid #ccc;
       border-radius: 10px;
       background: #e8e6e2;
       display: flex;
       flex-direction: column;
       align-items: center;
   }
   .report-title{
       margin-top:120px;
       margin-bottom:10px;
       background: #3a3539;
       color:#fff;
       font-size: 14pt;
       width:70%;
       padding:3px 0;
   }
   .report-title-text{
       
   }
   .report-content{
       width:100%;
   }
   .report-content-title{
      margin-bottom: 80px;
   }
   .report-content-title-text{
       font-weight: 550;
       font-size: 25pt;
   }
   .report-content-summary{
       display: flex;
       flex-direction: column;
   }
   .report-content-summary-text{
       
   }
   .report-content-summary-conclusion{
       font-weight: 550;
       font-size: 20pt;
       color:#409eff;
       display: block;
       margin:20px 0;
   }
   .report-content-summary-text-overall{
       font-size: 14pt;
       font-weight: 500;
       display: block;
       margin:10px 0 10px 0px; 
   }
   .report-content-summary-text-part{
       font-size: 12pt;
       display: block;
       margin: 5px ; 
       
   }
   .report-content-summary-text-part{
       
   }
   .report-content-summary-text-part{
       
   }
   .report-content-summary-text-part{
       
   }
   .report-content-chart{
       display: flex;
       flex-direction: row;
       margin:50px 50px 50px 100px;
       background: #fff;
   }
   
   .report-content-chart-bar{
       display: flex;
       flex-direction: row;
       margin-bottom: 30px;
   }
   .report-content-chart-bar-yAxios{
       height: 680px;
       width: 50px;
       display: flex;
   }
   .report-content-chart-bar-yAxios-score{
       display: flex;
       flex-direction: column;
       margin:10px 10px;
   }
   .score-text{
       display: block;
       margin: 10px 0;
   }
   .report-content-chart-bar-yAxios-level{
       display: flex;
       flex-direction: column;
   }
   .report-content-chart-bar-yAxios-brillent{
       display:flex;
       height: 80px;
       width: 200px;
       background: #409eff; 
       margin-left:10px;
       border-bottom:1px solid #fff;
       color:gold;
       font-size: 15pt;
       font-weight: 600;
       justify-content: center;
       align-items: center;
   }
   .report-content-chart-bar-yAxios-excelent{
       display:flex;
       height: 20px;
       width: 200px;
       background: #409eff95; 
       margin-left:10px;
       border-bottom:1px solid #fff;
       color:#8fffc7;
       font-size: 15pt;
       font-weight: 600;
       justify-content: center;
       align-items: center;
   }
   .report-content-chart-bar-yAxios-pass{
       display:flex;
       height: 55px;
       width: 200px;
       background: #409eff50; 
       margin-left:10px;
       border-bottom:1px solid #fff;
       color:#333;
       font-size: 15pt;
       font-weight: 600;
       justify-content: center;
       align-items: center;
   }
   .report-content-chart-bar-yAxios-fail{
       display:flex;
       height: 525px ;
       width: 200px;
       background: #aaa; 
       margin-left:10px;
       color:#fff;
       font-size: 15pt;
       font-weight: 600;
       justify-content: center;
       align-items: center;
   }
   .report-content-chart-items{
       display: flex;
       flex-direction: row;
       position: relative;
       height: 680px;
       width: 900px;
   }
   .report-content-chart-items-reading{
      width:120px;
      background: #409eff85;
      bottom:0%;
      left:25%;
      margin-right:10px;
      position: absolute;
      height:535px;
   }
   .report-content-chart-items-writing{
       width:120px;
       background: #409eff85;
       bottom:0%;
       left:40%;
       margin-right:10px;
       position: absolute; 
       height:480px;
   }
   .report-content-chart-items-listening{
       width:120px;
       background: #409eff85;
       bottom:0%;
       left:55%;
       margin-right:10px;
       position: absolute;
       height:480px; 
   }
   .report-content-chart-items-speaking{
       width:120px;
       background: #409eff85;
       bottom:0%;
       left:70%;
       height:480px;
       margin-right:10px;
       position: absolute; 
   }
   @media screen and (max-width: 1024px){
    .report-content{
        width:100%;
    }
    .report-content-chart{
        display: flex;
        flex-direction: row;
        margin:50px 10px;
        background: #fff;
        height:fit-content;
    }
    .report-content-chart-items{
        display: flex;
        flex-direction: column;
        position: relative;
        height: auto;
        width: 100%;
    }
    .report-content-chart-bar{
        display: flex;
        flex-direction: row;

        margin-bottom: 0px;
    }
    .report-content-chart-bar-yAxios-score{
        display: none;
        flex-direction: column;
        margin:10px 10px;
    }
    .score-text{
        display: none;
        margin: 10px 0;
    }
    .report-content-chart-bar-yAxios{

        display: flex;
    }
    .report-content-chart-bar-yAxios-standard {
        display: flex;
        flex-direction:column;
        
    }
    .report-content-chart-bar-yAxios-brillent, 
    .report-content-chart-bar-yAxios-excelent, 
    .report-content-chart-bar-yAxios-pass, 
    .report-content-chart-bar-yAxios-fail
    {
        display:flex;
        width:20px;
        margin-left:0px;
        font-size: 10pt;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        writing-mode: vertical-lr;
    }
   
    .report-content-chart-bar-yAxios-brillent{
       
        background: #409eff; 
        border-bottom:1px solid #fff;
        color:gold; 
    }
    .report-content-chart-bar-yAxios-excelent{
       
        background: #409eff95; 
        border-bottom:1px solid #fff;
        color:#8fffc7;
    }
    .report-content-chart-bar-yAxios-pass{
       
        background: #409eff50; 
        border-bottom:1px solid #fff;
        color:#333;
    }
    .report-content-chart-bar-yAxios-fail{
       
        background: #aaa; 
        color:#fff;
    }
    .report-content-chart-bar-yAxios-score{
        display: none;
        flex-direction: column;
        margin:10px 10px;
    }
 
    .report-content-chart-items-reading,
    .report-content-chart-items-writing,
    .report-content-chart-items-listening,
    .report-content-chart-items-speaking
    {
        bottom:0;
        width:50px;
        writing-mode: vertical-rl;
    }
    
    .report-content-chart-items-reading{

       background: #409eff85;
   
       left:0;
       margin-right:10px;
       position: absolute;
       height:85%;
    }
    .report-content-chart-items-writing{
        
        background: #409eff85;
   
        left:20%;
        margin-right:10px;
        position: absolute; 
        height:79%;
    }
    .report-content-chart-items-listening{
       
        background: #409eff85;
      
        left:40%;
        margin-right:10px;
        position: absolute;
        height:79%; 
    }
    .report-content-chart-items-speaking{
        
        background: #409eff85;

        left:60%;
        height:70%;
        margin-right:10px;
        position: absolute; 
    }
   }
   </style>